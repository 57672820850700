<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div >
 
    <div class="m-2">
      <h2>{{ $t("FilterBySpecialty") }}</h2>
      <v-select
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
          class="w-full "
          label="SpecialityName"
          v-model="selectedSpecialityFilter"
          :options="HospitalParentSpecialties"
          :reduce="(item) => item"
          :placeholder="$t('ParentSpecialities')"
          @input="select(selectedSpecialityFilter)"
        >
        </v-select>
    </div>

        <b-row >
     <b-col cols="12" md="5">
      <vs-card
          style="height: 40vh; overflow-y: scroll"
        >
          <h3 class="ActiveCardHeader primaryBackColor">{{ $t("Specialities") }}</h3>
          <div class="m-1">
            <vs-input
            v-model="search.SpecialityName"
            :placeholder="$t('Search')"
            class="w-full"
            name="search"
          />
            <div
              v-for="item in filterSpeciality"
              :key="item.ID"
              class="shadow-md w-full mt-2"
            >
              <b-row >
                <b-form-checkbox
                  v-model="item.IsSelected"
                  @input="ChangeSpecialitySelect(item)"
                  :disabled="(item.CantChecked==true)"
                ></b-form-checkbox>
                {{ item.Name }}
              </b-row>
            </div>
          </div>
        </vs-card>
     </b-col>
<b-col  cols="12" md="1" class="mt-5">

  <b-button
  :disabled="SpecialitySelected.length==0"
                size="small"
                @click="gofromSpecialityToSpecialitySelect"
                :variant="SpecialitySelected.length==0?'white':'primary'"
                style="border-radius: 20%; opacity: 1; border-color: black;border: 2px solid #1F1F1F"
                >
                <feather-icon
                        :icon="!$vs.rtl? 'ArrowRightIcon' :'ArrowLeftIcon'"                     
                         class="cursor-pointer"
                        ></feather-icon>
              </b-button>
  <br/>
 
  <b-button
  :disabled="SpecialityUnSelected.length==0"

                size="small"
                @click="gofromSpecialitySelectToSpeciality"
                class="mt-1"
                :variant="SpecialityUnSelected.length==0?'white':'primary'"
                style="border-radius: 20%; opacity: 1; border-color: black;border: 2px solid #1F1F1F"
                >
                <feather-icon
                          :icon="!$vs.rtl? 'ArrowLeftIcon' :'ArrowRightIcon'"                          
                          class="cursor-pointer"
                        ></feather-icon>
              </b-button>
  
                       
</b-col>
     <b-col cols="12" md="6">
        <vs-card
          class=""
          style="height: 40vh; overflow-y: scroll"
        >
          <h3 class="ActiveCardHeader secondaryBackColor">{{ $t("SpecialtiesSelected") }}</h3>
          <div
          v-for="(item, index) in HospitalChiledSpecialties"
            :data="item.Specialty"
            :key="index.ID"
            v-if="item.IsActive!=false"
              class=" mt-2 m-1"
            >
              <b-row >
                <b-form-checkbox
                  v-model="item.IsSelected"
                  @input="ChangeSpecialityUnSelect(item)"
                ></b-form-checkbox>
               <span v-if="item.Specialty">{{ item.Specialty.Name }}</span>
              </b-row>
            </div>
          
        </vs-card>
      </b-col>

      </b-row>

      <b-row  style="text-align:center">
          <b-col >
            <u @click="SaveSpecialities()" style="font-size: 20px;text-decoration: underline;">{{
              $t("Save")
            }}</u>
          </b-col>
          <b-col >
            <b-button
              variant="primary"
              @click="AddTreatment()"
              class=""
              >{{ $t("AddTreatments") }}
            </b-button>
          </b-col>
        </b-row>
        <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button> 
  </div>
</template>

<script>
//import LoginFirebase from "./LoginFirebase.vue"
//import LoginAuth0 from "./LoginAuth0.vue"
import moduleHospital from "@/store/hospital/moduleHospital.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";

import { domain, mainSetting } from "@/gloabelConstant.js";
import {
    
  BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow,
BFormCheckbox
} from "bootstrap-vue";
export default {
  components:{
    
    BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow,
BFormCheckbox},
  data() {
    return {
      EnglishLan:false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      Model: {},
      search: {},
      HospitalChiledSpecialties: [],
      HospitalParentSpecialties: [],
      SpecialitySelected:[],
      SpecialityUnSelected:[],
      selectedSpecialityFilter: { ID: 0 },
      childSpecialties:[]
    };
  },
  computed: {
  
    // childSpecialties() {
    //   return this.$store.state.SpecialtyList.childSpecialties;
    // },
    filterSpeciality() {
      if (this.search.SpecialityName) {
        var vm = this;
        return this.childSpecialties.filter(function (obj) {
          return (
            obj.Name.toLowerCase().indexOf(
              vm.search.SpecialityName.toLowerCase()
            ) > -1
          );
        });
      } else return this.childSpecialties;
    },
  },
  methods: {
    ChangeSpecialitySelect(Speciality){
      if(Speciality.IsSelected==true){
        this.SpecialitySelected.push(Speciality)

      }
      else{
        var index = this.SpecialitySelected.findIndex(
        (obj) => obj.ID == Speciality.ID
      );
      this.SpecialitySelected.splice(index, 1);

      }
    },
gofromSpecialityToSpecialitySelect(){
  debugger
  this.SpecialitySelected.forEach(spec => {
    var hospitalParent = {};
        hospitalParent.Specialty = {};
        hospitalParent.Specialty.Name = spec.Name;
        hospitalParent.Specialty.SpecialtyID = spec.ID;
        hospitalParent.Specialty.HospitalID =
          this.$store.state.AppActiveUser.Hospital.ID;
        hospitalParent.SpecialtyID = spec.ID;
        hospitalParent.IsActive =  true;
        hospitalParent.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        spec.IsSelected =  false
    this.HospitalChiledSpecialties.unshift(hospitalParent);

      debugger;
      var index = this.childSpecialties.findIndex(
        (obj) => obj.ID == spec.ID
      );
      if (index == -1) {
        this.HospitalParentSpecialties.push(spec);
      } else {
        this.childSpecialties.splice(index, 1);
      }
      
  });
  this.SpecialitySelected = [];
  
},
ChangeSpecialityUnSelect(Speciality){
      if(Speciality.IsSelected==true){
        this.SpecialityUnSelected.push(Speciality)

      }
      else{
        var index = this.SpecialityUnSelected.findIndex(
        (obj) => obj.SpecialtyID == Speciality.SpecialtyID
      );
      this.SpecialityUnSelected.splice(index, 1);

      }

    },
gofromSpecialitySelectToSpeciality(){
  debugger
  this.SpecialityUnSelected.forEach(Speciality => {
    Speciality.IsSelected =  false

    var index = this.HospitalChiledSpecialties.findIndex(
        (obj) => obj.SpecialtyID == Speciality.SpecialtyID
      );
  this.childSpecialties.unshift(Speciality.Specialty);
  this.HospitalChiledSpecialties.splice(index, 1);    
  });
  this.SpecialityUnSelected = [];
},
    select(item) {
      debugger;
      this.selectedSpecialityFilter = item;
      this.ChangeParentSpeciality(item);
      this.GetChiledSpecialities(item);
    },
    GetChiledSpecialities(item) {
      debugger
      var data = {};
      data.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
      data.SpecialityID = item.SpecialtyID;
      this.$store
        .dispatch("HospitalList/GetHospitalChiledSpecialty", data)
        .then((res) => {
            this.HospitalChiledSpecialties= [];
          this.HospitalChiledSpecialties = res.data.Data;
          if (this.childSpecialties && this.HospitalChiledSpecialties) {
      var newspecialtyList = [];

        this.childSpecialties.forEach(element => {
          var index = this.HospitalChiledSpecialties.findIndex(
            (x) => x.SpecialtyID == element.ID
          );

          var index2 = this.HospitalChiledSpecialties.findIndex(
            (x) => x.SpecialtyID == element.ID &&x.IsActive==false
          );
         
          if(index==-1){
            {
              if(index2!=-1){
                element.CantChecked = true;
              }
            newspecialtyList.push(element);

            }
          }
      });
      this.childSpecialties = newspecialtyList;
      }
          // this.HospitalChiledSpecialties= [];
          // this.HospitalChiledSpecialties = res.data.Data;
          // this.HospitalChiledSpecialties.forEach((element) => {
          //   debugger;
          //   var index = this.childSpecialties.findIndex(
          //     (x) => x.ID == element.SpecialtyID
          //   );
          //   this.childSpecialties[index].IsSelected = true;
          //    var index2 = this.childSpecialties.findIndex(
          //     (x) => (x.ID == element.SpecialtyID &&element.IsActive==false)
          //   );
          //   if (index2 != -1) {
          //     this.childSpecialties[index2].CantChecked = true;
          //   }
          // });
        });
    },
    ChangeParentSpeciality(item) {
      this.$store.dispatch(
        "SpecialtyList/GetSpecialtyByParentSpecialtyID",
        item.SpecialtyID
      ).then(res=>{
        this.childSpecialties = res.data.Data
      })
    },
    AddTreatment() {
      this.$router.push("/hospital/AddTreatmentSurgery");
    },
    back() {
      this.$router.go(-1);
    },
    SpecialityChecked(item) {
      debugger;
      if (item.IsSelected == true) {
        this.AddHospitalSpeciality(item);
      } else {
        this.DeleteHospitalSpeciality(item);
      }
    },
    DeleteHospitalSpeciality(item) {
      var index = this.HospitalChiledSpecialties.findIndex(
        (x) => x.SpecialtyID == item.ID
      );
      this.HospitalChiledSpecialties.splice(index, 1);
    },
    AddHospitalSpeciality(item) {
      var index = this.HospitalChiledSpecialties.findIndex(
        (x) => x.SpecialtyID == item.ID
      );
      if (index >= 0) {
        //alert
      } else {
        var hospitalParent = {};
        hospitalParent.Specialty = {};
        hospitalParent.Specialty.Name = item.Name;
        hospitalParent.IsActive =  true;
        hospitalParent.Specialty.SpecialtyID = item.ID;
        hospitalParent.Specialty.HospitalID =
          this.$store.state.AppActiveUser.Hospital.ID;
        hospitalParent.SpecialtyID = item.ID;
        hospitalParent.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        this.HospitalChiledSpecialties.push(hospitalParent);
      }
    },
    SaveSpecialities() {
      this.$vs.loading();

      debugger;
      if (this.HospitalChiledSpecialties.length > 0) {
        this.$store
          .dispatch(
            "HospitalList/SaveChiledSpecialities",
            this.HospitalChiledSpecialties
          )
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
              //  this.back();
            }
            this.$vs.loading.close();
          });
      } else {
        var data = {};
        data.hospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        data.specialityID = this.selectedSpecialityFilter.SpecialtyID;
        this.$store
          .dispatch("HospitalList/DeleteOldChiledSpecialities", data)
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
              //  this.back();
            }
            this.$vs.loading.close();
          });
      }
    },
  },
  created() {
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    this.$store.commit("SpecialtyList/SET_ChildSpecialty", []);

    this.$store.dispatch("SpecialtyList/GetParentSpecialty");
    this.$vs.loading();

    this.$store
      .dispatch(
        "HospitalList/GetHospitalParentSpecialty",
        this.$store.state.AppActiveUser.Hospital.ID
      )
      .then((res) => {
        this.HospitalParentSpecialties = res.data.Data;
        this.HospitalParentSpecialties.forEach(element => {
          element.SpecialityName = element.Specialty.Name
        });
        this.$vs.loading.close();
      });
  },
};
</script>

<style >
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
.Active {
  color: #2753D9;
  padding: 0px;
  border-radius: 12px;
}
.InActive {
  padding: 0px;
  border-radius: 12px;
}
p {
  font-size: medium;
}
</style>
